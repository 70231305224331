@import "../../assets/styles/utils";
// .comp-root-footer{
//     position: relative;
//     z-index: 999998;
// }
.footer{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: rgba(#000,.8);
    @include res(padding-top, 50px, 20 / 50);
    position: relative;
    .footer-contact{
        text-transform: uppercase;
    }
    ul{
        @include res(margin-bottom, 30px, 10 / 30);
    }
    ul p{
        width: 100%;
        color: #a4a4a4;
        @include res(font-size, 18px, 12 / 18);
        @include res(margin-bottom, 30px, 15 / 30);
        font-family:font-title;
    }
    ul li{
        dl{
            .submit{
                color: #fff;
                background: $color-red;
                width: 100%;
                @include res(height, 40px, 28 / 40);
                text-align: center;
                @include res(line-height, 40px, 28 / 40);
                @include res(font-size, 18px, 12 / 18);
                @include res(margin-top, 10px, 6 / 10);
                font-family:font-title;
                position: relative;
                z-index: 99;
                a{
                    position: relative;
                    z-index: 99;
                    display: block;
                }
                button{
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    border: none;
                    background: none;
                    position: relative;
                    z-index: 99;
                    color: #fff;
                    cursor: pointer;
                    &:focus{
                        outline:none;
                    }
                }
                .mask{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 0;
                    z-index: 9;
                    transition: 0.15s ease-out;
                }
            }
            .submit:hover .mask{
                height: 100%;
                background: $color-blue;
            }
        }
        dl {
            dd{
                color: #fff;
                position: relative;
                span,b{
                    // @include res(font-size, 14px, 12 / 14);
                    line-height: 1.6;
                    font-family: font-article;
                    font-weight: normal;
                }
                a{
                    display: block;
                    line-height: 1.8;
                    color: #fff;
                    // @include res(font-size, 14px, 12 / 14);
                    font-family:font-article;
                    transition: color 0.5s linear 0.1s;
                }
                input,select,textarea{
                    background: none;
                    outline: none;
                    border: none;
                    color: #fff;
                    width: 100%;
                    @include res(height, 40px, 28 / 40);
                    font-family:font-article;
                    @include res(font-size, 16px, 14 / 16);
                }
                input::-webkit-input-placeholder,textarea::-webkit-input-placeholder{
                    color: #fff;
                    @include res(font-size, 16px, 14 / 16);
                    font-family:font-article;
                    transition: .2s;
                }
                select{
                    @include res(font-size, 16px, 14 / 16);
                }
                select option{
                    color: #000;
                }
                textarea{
                    margin-top: 10px;
                    @include res(height, 80px, 30 / 80);
                }
                input:focus::-webkit-input-placeholder,
                textarea:focus::-webkit-input-placeholder {
                  color: transparent;
                }
                &.code{
                    position: relative;
                    .code-img{
                        width: 70px;
                        height: 30px;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        border: 1px solid #4d4d4d;
                        cursor: pointer;
                        img{
                            width: 100%;
                            height: 100%;
                            display: block;
                        }
                    }
                }
                .facebook-icon,.linkedin{
                    @include res(width, 40px, 30 / 40);
                    @include res(height, 40px, 30 / 40);
                }
                .footer_logo{
                    width: 65%;
                }
                .line{
                    width: 0;
                    height:1px;
                    background: $color-red;
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    transition: 0.2s ease-out;
                }
            }
        }
        h1{
            color: #fff;
            @include res(font-size, 22px, 14 / 22);
            font-family:font-header;
        }
    }
    ul:nth-child(1) div
    {
        padding-right: 80px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    ul:nth-child(1) li{
        border-left: 1px solid #4d4d4d;
        padding-left: 20px;
        margin-bottom: 10px;
        @media screen and(max-width:1440px){
            width:50%;
        }
    }
    ul:nth-child(1) li:last-child{
        margin-right: 0;
    }
    ul:nth-child(1) li dl{
        @include res(margin-top, 25px, 15 / 25);
    }
    ul:nth-child(2) li dl dd:nth-child(3){
        @include res(margin-top, 32px, 16 / 32);
        @include res(margin-bottom, 32px, 16 / 32);
    }
    ul:nth-child(2) li dl dd a{
        display: inline-block;
    }
    ul:nth-child(3){
        @include res(width, 358px, 300 / 358);
    }
    ul:nth-child(3) li dl dd{
        border-bottom: 1px solid #fff;
    }
}
.footer ul li dl .active .line{
    width:100%;
}
.footer .product .item0 dl dd:hover a{
    color: $color-sky-blue;
    transition: color 0.25s cubic-bezier(.19,1,.22,1);
}
.footer .product .item4 dl dd:hover a{
    color: $color-bluegreen;
    transition: color 0.25s cubic-bezier(.19,1,.22,1);
}
.footer .product .item1 dl dd:hover a{
    color: $color-yellow;
    transition: color 0.25s cubic-bezier(.19,1,.22,1);
}
.footer .product .item2 dl dd:hover a{
    color: $color-red;
    transition: color 0.25s cubic-bezier(.19,1,.22,1);
}
.footer .product .item3 dl dd:hover a{
    color: $color-blue;
    transition: color 0.25s cubic-bezier(.19,1,.22,1);
}
.footer ul:nth-child(2) li dl dd b:hover{
    border-bottom: 1px solid $color-blue;
}
.footer ul:nth-child(2) li dl dd b:hover a{
    color: $color-blue;
}

@media (max-width:1440px) {
    .footer{
        ul:nth-child(1) div {
            padding-right: 100px;
        }
    }
}

@media (max-width:1024px) {
  .footer {
    ul:nth-child(1) div {
        padding-right: 50px;
        li {
          padding-left: 10px;
        }
    }
  }
}

@media (max-width:768px) {
    .footer ul:nth-child(1){
        display: none;
        // display: flex;
        // justify-content: space-between;
        // flex-wrap: wrap;
        
    }
    .footer ul:nth-child(1) li{
        margin: 10px;
    }
    .footer ul:nth-child(3){
        width: 100%;
    }
    .footer ul li dl dd.code .code-img{
        width: 60px;
        height: 25px;
    }
}

.copy_right{
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #4d4d4d;
    background: rgba(#000,.8);
    display: flex;
    align-items: center;
    font-family: font-article;
    &_left{
        flex: 1;
        color: #8e8e8e;
        // @include res(font-size, 18px, 12 / 18);
        a{
            transition: color 0.5s linear 0.1s;
        }
        a:hover{
            color: $color-red;
            transition: color 0.25s cubic-bezier(.19,1,.22,1);
        }
    }
    &_right{
        color: #8e8e8e;
        // @include res(font-size, 18px, 12 / 18);
        cursor: pointer;
        a:nth-child(1){
            margin-right: 20px;
        }
        a{
            transition: color 0.5s linear 0.1s;
        }
        a:hover{
            color: $color-red;
            transition: color 0.25s cubic-bezier(.19,1,.22,1);
        }
    }
}
@media (max-width:500px){
    .copy_right{
        display: block;
        text-align: center;
        &_left{
            color: #8e8e8e;
            @include res(font-size, 18px, 12 / 18);
            line-height: 2;
        }
        &_right{
            color: #8e8e8e;
            @include res(font-size, 18px, 12 / 18);
        }
    }
}
.dwtop{
    @include res(width, 50px, 30 / 50);
    @include res(height, 50px, 30 / 50);
    @include res(line-height, 50px, 30 / 50);
    background: rgba(#000,.2);
    position: fixed;
    right: 5%;
    bottom: 12%;
    top: auto;
    z-index: 99;
    cursor: pointer;
    border: 1px solid #fff;
    text-align: center;
    transition: 0.2s ease-out;
    display: none;
    i{
        width: 100%;
        height: 100%;
        @include res(font-size, 18px, 14 / 18);
        color: #fff;
        text-align: center;
    }
    &:hover{
        padding-bottom: 5px;
    }
    &.foot-top{
        position: absolute;
        top: 5%;
        bottom: auto;
        right: 5%;
    }
}