@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

body {
  @include res(font-size, 16px, 14 / 16);
  font-family: '微软雅黑';
}
.comp-root{
  overflow: hidden;
  position: relative;
}
.container-fluid {
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);
}
@font-face {
  font-family: 'font-article';
  src: url('../fonts/swz721n.eot');
  src: url('../fonts/swz721n.woff') format('woff'),
  url('../fonts/swz721n.ttf') format('truetype'),
  url('../fonts/swz721n.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'font-header';
  src: url('../fonts/OSWALD.eot');
  src: url('../fonts/OSWALD.woff') format('woff'),
  url('../fonts/OSWALD.ttf') format('truetype'),
  url('../fonts/OSWALD.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'font-title';
  src: url('../fonts/FRUTIGER87-CONDENSEDEXTRABLACK.eot');
  src: url('../fonts/FRUTIGER87-CONDENSEDEXTRABLACK.woff') format('woff'),
  url('../fonts/FRUTIGER87-CONDENSEDEXTRABLACK.ttf') format('truetype'),
  url('../fonts/FRUTIGER87-CONDENSEDEXTRABLACK.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.news-pages{
    text-align: center;
    @include res(font-size, 20px, 12 / 20);
    @include res(padding-top, 40px, 20 / 40);
    a{
        font-family: font-title;
        display: inline-block;
        position: relative;
        margin: 0 5px;
        @include res(width, 40px, 20 / 40);
        &::after{
            content: '';
            display: block;
            position: absolute;
            bottom: -12px;
            left: 0;
            height: 4px;
            background: $color-red;
            width: 0;
            transition: 0.3s;
        }
        &:hover,&.hover,&.active{
          &::after{
            width: 100%;
          }
        }
    }
}
.clearfix{
  zoom: 1;
  &::after{
    content: '';
    display: block;
    height: 0;
    clear: both;
  }
}

.bg-gray{
  background-color: #efefef;
}

.Facebook {
  width: 600px;
  height: auto;
  position: fixed;
  top: 0;
  left: -3000px;
  img {
      width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
  }
}
