@font-face {
  font-family: "iconfont"; /* Project id 1870004 */
  src: url('iconfont.woff2?t=1651799266213') format('woff2'),
       url('iconfont.woff?t=1651799266213') format('woff'),
       url('iconfont.ttf?t=1651799266213') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-weizhi:before {
  content: "\e603";
}

.icon-verify:before {
  content: "\e63f";
}

.icon-maidian:before {
  content: "\e60a";
}

.icon-fanhuidingbu:before {
  content: "\e6e6";
}

.icon-bianji:before {
  content: "\e61c";
}

.icon-map:before {
  content: "\e747";
}

.icon-email:before {
  content: "\e689";
}

.icon-whatsapp:before {
  content: "\e63c";
}

.icon-nickname:before {
  content: "\e618";
}

.icon-guanbi:before {
  content: "\e608";
}

.icon-jiantou-copy:before {
  content: "\e60c";
}

.icon-arrow-right:before {
  content: "\e600";
}

.icon-youjiantou:before {
  content: "\e601";
}

.icon-iconfontyoujiantou:before {
  content: "\e602";
}

.icon-search:before {
  content: "\e6a5";
}

