$color-red: #ff3615;
$color-red-light:#d56b6d;
$color-blue: #1e4cd5;
$color-blue-light:#2456ab;
$color-yellow: rgba(251,124,47,1);
$color-gray-text: #979797;
$color-bg:#dfe4e6;
$header-height-base: 120px;
$header-height-ratio: 80 / 120;
$color-sky-blue: #17abf1;
$color-bluegreen: #1794A6;

// 缩放效果比例
$header-height-active-ratio: $header-height-ratio;
// 缩放效果持续时间
$header-anime-duration: 0.25s;

$container-gutter-base: 50px;
$container-gutter-ratio: 30 / 50;

$hamburger-layer-width: 32px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: #fff;
$hamburger-layer-border-radius: 0;

// 响应式断点，需要从小到大写
$layout-responsive-breakpoint: (
  // 手机
  xs: (min-width: 0px, container-width: 100%),
  // 平板
  sm: (min-width: 768px, container-width: 720px),
  // 小屏
  md: (min-width: 992px, container-width: 960px),
  // 大屏
  lg: (min-width: 1367px, container-width: 1230px)
) !default;

.clear{
  clear: both;
}
body{
  overflow-x: hidden;
}
.center{
  width: 1360px;
  margin: 0 auto;
}
.comp-header-nav-item-mask{
  width: 100%;
  height: 100%;
  background: rgba(#000, 0.3);
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  display: none;
}
.jason-map{
  z-index: 9999999;
}