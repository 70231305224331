@import "../../assets/styles/utils";
.comp-header {
	.container-fluid{
		// position: relative;
	}
	background: rgba(#000, 0.5);
	// overflow: hidden;
	position: fixed;
	@include res(top, 38px, 16 / 38);
	left: 0;
	z-index: 999;
	width: 100%;
	color: #000;
	transition: 0.5s !important;
	&-wrapper {
		// position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include res(height, $header-height-base, $header-height-ratio);
	}
	&-logo {
		position: relative;
		height: 60%;
		display: block;
		&-pic {
			height: 100%;
			width: auto;
			display: block;
			// max-width: 50vw;
		}
  	}
	&-last {
		display: flex;
		align-items: center;
		height: 100%;
	}
	&-nav {
		// @extend %hidden-md-down;
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
		@media screen and(max-width:1205px) {
			display: none;
		}
		& ul{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			height: 100%;
		}
		&-item {
			display: flex;
			align-items: center;
			height: 100%;
			border-bottom: 2px solid transparent;
			@include res(font-size, 18px, 16 / 18);
			& >a{
				display: flex !important;
				width: 100%;
				height: 100%;
				align-items: center;
				font-family: font-header;
				@include res(font-size, 20px, 16 / 20);
				color: #fff;
			}
			&:not(:last-child) {
				margin-right: vw(40);
			}
			& {
				transition: all .15s;
			}
			&:hover, &.active {
				border-bottom-color: #fff;
			}
		}
	}
	&-hamburger {
		display: none;
		@media screen and(max-width:1205px) {
			display: flex;
		}
	}
	&-lang {
		padding: 3px 14px;
		font-size: 16px;
		@include res(margin-left, 35px, 14 / 35);
		color: #fff;
	}
	&, &-wrapper {
		transition: all $header-anime-duration;
	}
	&.hover{
		padding-bottom: 216px;
	}
	&.active{
		top: 0;
		transition: 0.5s;
	}
	&.active &-wrapper {
		height: $header-height-base * $header-height-active-ratio;
	}
}
.comp-header-hamburger{
	flex: 1;
	justify-content: flex-end;
}
.nav-item-icon{
	margin-left: 6px;
}
.icon-search{
	font-size: 26px;
	transition: 0.2s;
}
.comp-header-last .comp-header-lang i:hover{
	color:$color-yellow;
}
.logo1{
	// width: 100%;
	transition:all 0.5s;
}
.logo2{
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	transition:all 0.5s;
}
.comp-header.active {
	.logo1{
		opacity: 0;
	}
	.logo2{
		opacity: 1;
	}
}
.comp-header-nav-item-hover a{
	border: none;
}

@media(max-width:1035px) {
	.comp-header-nav-item:not(:last-child){
		margin-right: vw(25);
	}
}
@media (max-width: 1205px){
	.logo1 {
	  opacity: 0;
	}
	.logo2 {
	  opacity: 1;
	}
	.comp-header{
		background: #fff;
		top: 0;
	}
	.hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
		background: #1d2088;
	}
	.comp-header-lang{
		display: none;
	}
}
@media (max-width: 991px){
	.search_box{
		display: none!important;
	}
}
.search_box{
	width: 100%;
	height: 100%;
	background: rgba(#000, 0.9);
	position: fixed;
	top: 0;
	right: 0;
	z-index: 9999;
	display: none;
	.close_btn{
	@include res(width, 62px, 40 / 62);
	@include res(height, 62px, 40 / 62);
	position: absolute;
	top: 0;
	right: 0;
	z-index: 9999;
	background: $color-yellow;
	cursor: pointer;
	text-align: center;
	i{
		color: #333;
		@include res(font-size, 30px, 16 / 30);
		@include res(line-height, 62px, 40 / 62);
	}
  }
  .enter{
	  @include res(width, 1000px, 768 / 1000);
	  margin: 0 auto;
	  @include res(margin-top, 252px, 120 / 252);
	  .title{
		  color: #9b9b9b;
		  @include res(font-size, 20px, 14 / 20);
		  font-family: 'font-header';
	  }
	  .input{
		  width: 100%;
		  border-bottom: 1px solid $color-red;
		  @include res(margin-top, 34px, 20 / 34);
		  position: relative;
		  form{
			display: flex;
			align-items: center;
		  }
		  input{
			  flex: 1;
			  width: 100%;
			  background: none;
			  border: none;
			  outline: none;
			  color: #7a7a7a;
			  @include res(font-size, 30px, 20 / 30);
			  line-height: 1.8;
			  font-family: 'font-header';
		  }
		  button{
			  color: #fff;
			  @include res(font-size, 30px, 20 / 30);
			  cursor: pointer;
			  background: none;
			  border: none;
			  outline: none;
		  }
		  .line{
			  width: 0;
			  height:1px;
			  background: $color-yellow;
			  position: absolute;
			  bottom: -1px;
			  left: 0;
			  transition: 0.3s ease-out;
		  }
	  }
  }  
}
.search_box .enter .active .line{
	width: 100%;
}
.logo_nav{
	display: flex;
	flex: 1;
	height: 100%;
	align-items: center;
	.comp-header-logo{
		display: flex;
		flex: 1;
		width: auto;
		max-width: 216px;
		// .logo2{
		// 	width: 60%;
		// }
	}
}
.comp-header-nav{
	flex: 1;
	height: 100%;
}
.child_column{
	// width: 100vw;
	height: auto;
	max-height: 216px;
	overflow: hidden;
	display: flex;
	align-items: center;
	// @include res(padding-left, 145px, 30 / 145);
	// @include res(padding-right, 145px, 30 / 145);
	// @include res(padding-top, 30px, 10 / 30);
	// @include res(padding-bottom, 30px, 10 / 30);
	position: absolute;
	// @include res(left, -80px, -30 / -80);
	// @include res(right, -80px, -30 / -80);
	left: 0;
	right: 0;
	top: 100%;
	// display: none;
	transform: scaleY(0);
	transform-origin: top;
	opacity: 0;
	transition: .2s;
	&.on{
		opacity: 1;
		transform: scaleY(1);
	}
	ul{
		width: 100%;
		height: 100%;
		overflow: hidden;
		display: flex;
		justify-content: center;
		// @include res(padding-left, 80px, 30 / 80);
		// @include res(padding-right, 80px, 30 / 80);
		// flex-wrap: wrap;
		// flex: 1;
		li{
			width: 25%;
			height: 100%;
			overflow: hidden;
			display: flex;
			align-items: center;
			padding: 50px 30px;
			cursor: pointer;
			position: relative;
			&::after{
				content: '';
				display: block;
				width: 100%;
				height: 0;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				background: rgba(0,0,0,.07);
				transition: 0.2s ease-out;
			}
			.menu_img{
				position: relative;
				z-index: 1;
				bottom: 0;
				transition: 0.2s ease-out;
				@include res(width, 74px, 40 / 74);
				img{
					width: 100%;
					height: auto;
					display: block;
					transition: 0.2s ease-out;
				}
			}
			.menu_desc{
				flex: 1;
				margin-left: 8%;
				position: relative;
				z-index: 1;
				transition: 0.2s ease-out;
				h1{
					color: #000;
					@include res(font-size, 20px, 14 / 20);
					transition: 0.3s;
					font-family: 'font-title';
					@include res(margin-bottom, 6px, 2 / 6);
				}
				p{
					color: #000;
					@include res(font-size, 14px, 12 / 14);
					@include res(line-height, 22px, 16 / 22);
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					overflow: hidden;
					transition: 0.3s;
					font-family: 'font-article';
					@include res(margin-bottom, 20px, 10 / 20);
				}
				.read_more{
					// width: 100%;
					display: inline-block;
					height: auto;
					position: relative;
					a{
						color: #fff;
						font-family: 'font-article';
						padding-bottom: 4px;
						transition: 0.2s;
						font-size: 14px;
						display: inline-block !important;
						width: auto;
						position: relative;
						// border-bottom: 2px solid #fff;
					}
					.line{
						width:0;
						height: 2px;
						background: #fff;
						position: absolute;
						bottom: 0;
						left: 0;
						right: auto;
						transition: .3s;
					}
				}
			}
			&:hover{
				&::after{
					height: 100%;
				}
				.menu_img{
					transform: translateY(-10px);
				}
				.menu_desc {
					transform: translateY(10px);
				}
			}
			&:not(:first-child){
				border-left: 1px solid rgba(0,0,0, .1);
			}
		}
	}
	.more{
		border: 2px solid #fff;
		@include res(width, 250px, 150 / 250);
		@include res(height, 60px, 30 / 60);
		justify-content: center;
		text-align: center;
		display: flex;
		align-items: center;
		transition: 0.15s;
		a{
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			font-family: font-title;
			font-size: 20px;
			i{
				font-size: 20px;
				margin-top: 7px;
				margin-left: 20px;
				transition: 0.2s ease-out;
			}
		}
		&:hover{
			box-shadow: 5px 5px 0 #fff;
		}
		&:hover i{
			margin-left: 36px;
		}
	}
}
@media(max-width:991px){
	.child_column{
		display: none;
	}
}


.comp-header-nav ul li:nth-child(1) .child_column{
	background: $color-yellow;
}

// .comp-header-nav ul li:nth-child(2) .child_column{
// 	background: $color-bluegreen;
// }

.comp-header-nav ul li:nth-child(2) .child_column{
	background: $color-red-light;
}

.comp-header-nav ul li:nth-child(3) .child_column{
	background: $color-sky-blue;
}

.comp-header-nav ul li:nth-child(4) .child_column{
	background: $color-blue-light;
	ul li .menu_desc{
		h1,p{
			color: #fff;
		}
	}
}