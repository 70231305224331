@import "../../assets/styles/utils";

.comp-menu {
  @include cover(100, fixed);
  @include res(padding-top, $header-height-base, $header-height-ratio);
  width:100%;
  background-color: #fff;
  &-wrapper {
    position: absolute;
		@include res(top, $header-height-base, $header-height-ratio);
    overflow: hidden;
    height: 100%;
    width:90%;
    left: 5%;
    &-search{
      width: 100%;
      height: 64px;
      line-height: 64px;
      color: #b6b6b6;
      font-size: 16px;
      display: flex;
      border-bottom: 1px solid $color-blue;
      margin-top: 3%;
      position: relative;
      input{
        outline: none;
        border: none;
        flex: 1;
        font-family: font-header;
      }
      i{
        color: #333;
        cursor: pointer;
      }
      .line{
        width: 0;
        height:1px;
        background: $color-red;
        position: absolute;
        bottom: -1px;
        left: 0;
        transition: 0.3s ease-out;
      }
    }
  }
  &-close {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    color: #fff;
    background-color: #005bac;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 26px;
  }
  &-nav {
    &-item {
      position: relative;
      display: flex;
      font-size: 16px;
      padding: 14px 0;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: #CDCDCD;
        transform: scaleX(2);
      }
    }
  }

  // hover
  &, &-wrapper {
    transition: all .25s;
  }
  & {
    opacity: 0;
    visibility: hidden;
  }
  &-wrapper {
    transform: translate3d(100%, 0, 0);
  }
  &.active {
    opacity: 1;
    visibility: visible;
  }
  &.active &-wrapper {
    transform: translate3d(0, 0, 0);
  }
}
.comp-menu-nav-item{
  width: 100%;
  display: block;
  position: relative;
  .title{
    width: 80%;
    display: block;
    font-family: font-header;
  }
  i{
    position: absolute;
    top: 18px;
    right: 0px;
    cursor: pointer;
    transform:rotate(0);
    transition:all ease 0.3s;
  }
  .active_icon{
    transform:rotate(-180deg);
    color: $color-blue;
  }
  ul{
    padding-left:20px;
    margin: 20px 0 10px;
    display: none;
    li{
      width: 100%;
      line-height:2.2;
      a{
        font-size: 14px;
        display: block;
        color: #838383;
        font-family: font-article;
      }
    }
  }
}
.comp-menu-wrapper-search.active .line{
  width: 100%;
}